@import "base/_fonts.css";
@import "./base/_haelpers.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
.grecaptcha-badge {
  visibility: hidden;
  z-index: -100;
}

@import "base/_settings.css";
@import "base/_base.css";
@import "base/_home_page.css";
@import "./sliders/_provider.css";
@import "./base/_animtions.css";
